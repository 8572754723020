

const requireModule = require.context(".", true, /\.vue$/); //extract vue files inside tools folder
let tools= [];

requireModule.keys().forEach(fileName => {
  if (fileName === "./index.js") return; //reject the index.js file

  const appName = fileName.replace(/(\.\/|\.vue)/g, ""); //
  tools.push(appName);
});
export default tools;
