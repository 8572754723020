<template>
  <v-container  >
    <v-card color="grey lighten-3" elevation="0" class="pa-5">
      <v-row>
        <v-col cols="2" v-for="(tool,i) in getTools" :key="i">
          <v-card :to="'/'+tool" >
            <v-card-title primary-title class="justify-center py-15 text-h5"> {{ format(tool)}} </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import tools from "./tools";
import startCase from 'lodash/startCase'

export default {
  name: "toolsList",
  computed: {
    getTools() {
      return tools;
    },
  },
  methods:{
    format(text){
        return startCase(text);
    }
  }
};
</script>