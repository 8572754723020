<template>
  <v-container align="center" justify="center">
    <v-card color="grey" height="40vw" class="align-center" flat>
      <iframe
        height="100%"
        :src="url"
        frameborder="0"
        width="100%"
      ></iframe>
    </v-card>
    <v-text-field
      class="mt-2"
      outlined
      placeholder="Enter the URL (specify http/https) and press ENTER"
      filled      
      append-icon="mdi-reload"
      v-model="urlText"      
      @click:append="browse"
      @keyup.enter="browse"
    ></v-text-field>
  </v-container>
</template>

<script>
export default {
  name: "iframeTester",
  data() {
    return {
      url: "",
      urlText: '',
    };
  },
  methods:{
    browse(){
        console.log('clicked')
        this.url = this.urlText
    }
  }
};
</script>