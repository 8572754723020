import Vue from 'vue'
import camelCase from 'lodash/camelCase'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import tools from '@/components/tools'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

tools.forEach(tool => {
  const route = {
    path: '/'+ tool,
    name: tool,
    component: function () {
      return import('@/components/tools/'+ tool + '.vue')
    }
  }
  routes.push(route)
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
