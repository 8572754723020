<template>
<toolsList></toolsList>
  
</template>

<script>
  import toolsList from '@/components/toolsList.vue'

  export default {
    name: 'HomeView',

    components: {
      toolsList  
    },
  }
</script>
