import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{staticClass:"pa-5",attrs:{"color":"grey lighten-3","elevation":"0"}},[_c(VRow,_vm._l((_vm.getTools),function(tool,i){return _c(VCol,{key:i,attrs:{"cols":"2"}},[_c(VCard,{attrs:{"to":'/'+tool}},[_c(VCardTitle,{staticClass:"justify-center py-15 text-h5",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.format(tool))+" ")])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }